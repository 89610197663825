.form {
    font-family: Antonio;
    border-collapse: separate !important;
    &--link {
        font-size: 1rem;
        color: #333;
        text-decoration: none;
        padding-top: 10px;
        font-weight: bold;
    }

    &--link:hover {
        color: #666;
    }
}

.title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    padding: 10px 0 10px 0;
    flex-direction: row;
    gap: 10px;
    color: black;
    &__otherColor {
        color: black;
    }
}
.containerForm {
    border-radius: 7px;
    padding: 40px;
    width: 420px;
    height: auto;
}
