.input {
    background-color: var(--color-yellow);
    border-radius: 10px 10px 0 0;
}
.input_Docunecta {
    background-color: var(--color-yellow);
    border-radius: 10px 10px 0 0;
}
.input_Biyectiva {
    background-color: var(--color-yellow);
    border-radius: 10px 10px 0 0;
}
.input_Byprox {
    background-color: var(--color-byprox);
    border-radius: 10px 10px 0 0;
}
.input:focus {
    background-color: var(--color-yellow);
    border: 1px black solid !important;
    border-radius: 10px 10px 0 0;
    color: black;
}
