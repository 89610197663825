.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: 180px;
    background-color: rgba(58, 74, 102, 0.76);
    border-radius: 30px;
    padding: 40px;
    color: white;
    font-size: 30px;
}
