.sidebar {
    min-height: 100vh;

    &--header {
        &--button {
            border: none;
            background: none;
            padding: 0;
            margin: 0;
            outline: none;
            cursor: pointer;
            display: block;
            color: white;

            &:hover {
                opacity: 0.8;
            }

            &:focus {
                border: 1px;
            }
        }
    }

    &__close {
        width: 4.5rem;
    }

    &__open {
        width: 18rem;
    }
}

.quota {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
