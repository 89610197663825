//Generic
@import './generic/generic';
@import './generic/normalize';

//Settings
@import 'settings/font';
@import 'settings/color';

//Tools
@import 'tools/mixins';
@import './tools/responsive';

//Elements
@import 'elements/elements';
