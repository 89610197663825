@import '@/styles/tools/responsive';
.container {
    width: 100%;
    height: 100%;
}

.bannerImage {
    background-image: url('./../../assets/images/landing.png');
    height: 100vh;
    background-size: cover;
    background-position: center;
    width: 100%;
    transition: all 500ms ease;
}
.charmLogo {
    background-image: url('./../../assets/images/charm-logo.png');
    width: 300px;
    height: 50px;
    background-size: cover;
    font-size: 100px;
    position: fixed;
    bottom: 60px;
    left: 70px;
}
.textDevBy {
    font-family: 'Antonio';
    position: fixed;
    bottom: 4em;
    right: 3em;
    color: #333;
    font-size: 35px;
    font-weight: lighter;
}
.biyectivaLogo {
    background-image: url('./../../assets/images/biyectiva-logo.png');
    width: 390px;
    height: 63px;
    background-size: cover;
    font-size: 100px;
    position: fixed;
    bottom: 60px;
    right: 70px;
}
.layer {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

@include breakpoint(lessTablet) {
    .textDevBy {
        font-size: 20px;
        bottom: 100px;
        right: 115px;
    }

    .biyectivaLogo {
        width: 250px;
        height: 40px;
    }
    .charmLogo {
        width: 200px;
        height: 40px;
    }
}
@include breakpoint(mobile) {
    .textDevBy {
        bottom: 137px;
        right: 117px;
    }

    .biyectivaLogo {
        bottom: 100px;
    }
    .charmLogo {
        bottom: 50px;
    }
}
