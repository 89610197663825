.button {
    border: 1px solid var(--color-yellow);
    border-radius: 0 10px 0 0;
    color: black;
    background-color: transparent;
}
.button:hover {
    border: 1px solid var(--color-yellow);
    border-radius: 0 10px 0 0;
    color: #333;
    background-color: rgba(255, 255, 255, 0.154);
    font-weight: bold;
}

.button_Biyectiva {
    border: 1px solid var(--color-yellow);
    border-radius: 0 10px 0 0;
    color: black;
    background-color: transparent;
}
.button_Biyectiva:hover {
    border: 1px solid var(--color-yellow);
    border-radius: 0 10px 0 0;
    color: #333;
    background-color: rgba(255, 255, 255, 0.154);
    font-weight: bold;
}

.button_Docunecta {
    border: 1px solid var(--color-yellow);
    border-radius: 0 10px 0 0;
    color: black;
    background-color: transparent;
}
.button_Docunecta:hover {
    border: 1px solid var(--color-yellow);
    border-radius: 0 10px 0 0;
    color: #333;
    background-color: rgba(255, 255, 255, 0.154);
    font-weight: bold;
}

.button_Byprox {
    border: 1px solid var(--color-byprox);
    border-radius: 0 10px 0 0;
    color: black;
    background-color: transparent;
}
.button_Byprox:hover {
    border: 1px solid var(--color-byprox);
    border-radius: 0 10px 0 0;
    color: #333;
    background-color: rgba(255, 255, 255, 0.154);
    font-weight: bold;
}