.login {
    font-family: 'Antonio';
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    vertical-align: middle;
    &--form {
        max-width: 400px;
        width: 90%;

        &--illustration {
            height: 10em;
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: center;
            margin-bottom: 1em;
        }

        &--link {
            font-size: 1rem;
            color: #bcd3ee;
            text-decoration: none;
            padding-top: 20px;
            color: black;
            font-weight: bolder;
        }

        &--link:hover {
            color: #666;
        }
    }
    .containerForm {
        border-radius: 7px;
        padding: 40px;
        width: 420px;
        height: auto;
    }
}
