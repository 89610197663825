.container {
    width: 200px;
    height: 180px;
    box-shadow: 10px 10px 20px #6e6e6e29;
    border: 1px solid #6e6e6e29;
    border-radius: 20px;
    display: grid;
    grid-template-columns: 5fr;
    grid-template-rows: auto minmax(70px, auto) auto;
    grid-template-areas:
        'closeIcon closeIcon closeIcon closeIcon closeIcon '
        'fileName fileName fileName fileName fileName'
        'checkbox checkbox checkbox checkbox checkbox';
    padding: 12px;
    align-self: center;
    justify-self: center;
}

.iconContainer {
    width: 100%;
    grid-area: closeIcon;
    display: flex;
    justify-content: end;
    align-items: center;
}
.icon {
    grid-area: closeIcon;
    width: 27px;
    height: 27px;
    color: #ff0019;
    cursor: pointer;
}
.icon:hover {
    color: #e95967;
}
.fileName {
    grid-area: fileName;
    display: flex;
    justify-content: start;
    align-items: center;
    word-break: break-all;
    font-size: 15px;
    color: #2361bd;
}
.checkboxContainer {
    grid-area: checkbox;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    word-break: keep-all;
    font-size: 12px;
}
