@import '@/styles/tools/responsive';

.buttonSubmit {
    width: 250px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0 10px 1;
    border-radius: 50px;
    background-color: transparent;
    font-weight: bold;
    border-radius: 50px;
    border: 10px solid transparent;
    background: linear-gradient(to left, var(--color-yellow), black) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    color: #333;
    z-index: 10;
}

.buttonContainerRegister {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
    position: relative;
}
.buttonContainerLogin {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
    position: relative;
}
.buttonSubmit:hover {
    border: 1px solid var(--color-sky-blue);
    border-radius: 50px;
    color: var(--color-dark-blue);
    font-weight: bold;
    border-radius: 50px;
    border: 10px solid transparent;
    background: linear-gradient(to left, var(--color-yellow), black) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    position: relative;
    z-index: 1;
}

.buttonContainerLogin:after {
    content: 'INICIAR SESIÓN';
    width: 10em;
    height: 40px;
    display: flex;
    position: absolute;
    display: block;
    color: #333;
    top: 18px;
    left: 133px;
    font-weight: bold;
}

.buttonContainerRegister:after {
    content: 'REGISTRARSE';
    width: 10em;
    height: 40px;
    display: flex;
    position: absolute;
    display: block;
    color: #333;
    top: 18px;
    left: 153px;
    font-weight: bold;
}

@include breakpoint(lessTablet) {
    .buttonContainerLogin:after {
        content: 'INICIAR SESIÓN';
        width: 10em;
        height: 40px;
        display: flex;
        position: absolute;
        display: block;
        color: #333;
        top: 18px;
        left: 133px;
        font-weight: bold;
    }

    .buttonContainerRegister:after {
        content: 'REGISTRARSE';
        width: 10em;
        height: 40px;
        display: flex;
        position: absolute;
        display: block;
        color: #333;
        top: 18px;
        left: 153px;
        font-weight: bold;
    }
}
@include breakpoint(mobile) {
    .buttonContainerLogin:after {
        left: 140px;
    }

    .buttonContainerRegister:after {
        left: 40%;
    }
}


.buttonSubmit_Byprox {
    width: 250px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0 10px 1;
    border-radius: 50px;
    background-color: transparent;
    font-weight: bold;
    border-radius: 50px;
    border: 10px solid transparent;
    background: linear-gradient(to left, var(--color-byprox), black) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    color: #333;
    z-index: 10;
}
.buttonSubmit_Byprox:hover {
    border: 1px solid var(--color-sky-blue);
    border-radius: 50px;
    color: var(--color-dark-blue);
    font-weight: bold;
    border-radius: 50px;
    border: 10px solid transparent;
    background: linear-gradient(to left, var(--color-byprox), black) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    position: relative;
    z-index: 1;
}
.buttonSubmit_Biyectiva {
    width: 250px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0 10px 1;
    border-radius: 50px;
    background-color: transparent;
    font-weight: bold;
    border-radius: 50px;
    border: 10px solid transparent;
    background: linear-gradient(to left, var(--color-yellow), black) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    color: #333;
    z-index: 10;
}
.buttonSubmit_Biyectiva:hover {
    border: 1px solid var(--color-sky-blue);
    border-radius: 50px;
    color: var(--color-dark-blue);
    font-weight: bold;
    border-radius: 50px;
    border: 10px solid transparent;
    background: linear-gradient(to left, var(--color-yellow), black) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    position: relative;
    z-index: 1;
}

.buttonSubmit_Docunecta {
    width: 250px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0 10px 1;
    border-radius: 50px;
    background-color: transparent;
    font-weight: bold;
    border-radius: 50px;
    border: 10px solid transparent;
    background: linear-gradient(to left, var(--color-yellow), black) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    color: #333;
    z-index: 10;
}
.buttonSubmit_Docunecta:hover {
    border: 1px solid var(--color-sky-blue);
    border-radius: 50px;
    color: var(--color-dark-blue);
    font-weight: bold;
    border-radius: 50px;
    border: 10px solid transparent;
    background: linear-gradient(to left, var(--color-yellow), black) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    position: relative;
    z-index: 1;
}
