.navbar {
}

.main {
    width: 100%;
    height: 100%;
    //max-height: 100vh;
    padding: 0;
    margin: 0;
    border-radius: 0;
}
