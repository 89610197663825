.button {
    border: 1px solid var(--color-yellow);
    border-radius: 10px 0 0 0;
    color: black;
    background-color: transparent;
    cursor: pointer;
}
.button:hover {
    border: 1px solid var(--color-yellow);
    border-radius: 5px;
    color: #333;
    background-color: rgba(255, 255, 255, 0.298);
    font-weight: bold;
}
