.container {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 50% auto 15%;
    grid-template-areas:
        'dropZone dropZone dropZone dropZone dropZone'
        'cards cards cards cards cards'
        'buttonSend buttonSend buttonSend buttonSend buttonSend';
    padding: 2em 5em 2em 5em;
    gap: 20px;
}
.containerCards {
    grid-area: cards;
    width: 100%;
    height: 100%;
    display: grid;
    gap: 10px;
    padding-top: 10px;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    grid-template-rows: 180px auto;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.containerDropZone {
    grid-area: dropZone;
    width: 100% !important;
    height: 27em !important;
    background-color: rgb(231, 237, 241);
    border-radius: 20px;
}

.containerButton {
    grid-area: buttonSend;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonSend {
    width: 30em;
    height: 3.5em;
    background-color: #0d6efd;
    color: white;
    border-radius: 0.5em;
    border-color: #0d6efd;
    font-size: 20px;
}
.buttonSend:hover {
    background-color: rgb(5, 5, 170);
}
